<template>
  <v-container style="margin-bottom: 100px">
    <customer-info logo="true" dayDateWithBackBtn="true" customer="true" />

    <v-row>
      <v-col>
        <span>
          F+3/12<br />
          10/26/2020 11/17/2020<br />
          Day 1 of 12
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="12">
        <h1>PROJECT CLOSEOUT</h1>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1 cursor-pointer"
      hide-default-footer
      hide-default-header
    >
      <template v-slot:item.options="{ item }">
        <v-radio-group v-model="item.row" row>
          <v-radio label="Y" value="radio-1"></v-radio>
          <v-radio label="NA" value="radio-3"></v-radio>
        </v-radio-group>
      </template>

      <template v-slot:item.comments="{ item }">
        <v-text-field
          v-model="item.comments"
          label="Comments"
          required
        ></v-text-field>
      </template>
    </v-data-table>

    <v-row class="mt-10">
      <v-col cols="12">
        <v-textarea
          v-model="customerComments"
          outlined
          label="Customer Comments"
        ></v-textarea>
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="repComments"
          outlined
          label="Rep Comments"
        ></v-textarea>
      </v-col>
      <v-col cols="12">
        <a>Customer Signature</a><br />
        <a>Rep Signature</a>
      </v-col>
      <v-col md="12">
        <v-btn color="error" @click="$router.push({ name: '' })"
          >Closeout Project
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerInfo from "./CustomerInfo";

export default {
  name: "TodayCloseOut",
  components: { CustomerInfo },
  data() {
    return {
      repComments: "",
      customerComments: "",
      comments: "",
      row: null,
      headers: [
        { text: "Options", value: "options" },
        { text: "Questions", value: "questions" },
        { text: "Comments", value: "comments" },
      ],
      items: [
        {
          questions: "Final walkthrough with Customer?",
        },
        {
          questions:
            "Is there anything that would prevent you from referring to a friend?",
        },
        {
          questions: "Review Bid Items with Customer",
        },
        {
          questions: "Review Checklist Items with Customer",
        },
        {
          questions: "Valuable Freebies Provided ",
        },
        {
          questions: "Schedule return visit required?",
        },
        {
          questions: "Check with close?",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
